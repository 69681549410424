const IS_PROD = true;
const IS_LOCAL = false;
const IS_NEW_API = false;

let baseUrl = 'https://api-test.rxhometest.com/';
let url = 'https://test.rxhometest.com';
let qrUrl = 'https://qr-test.rxhometest.com';
// let adminUrl = 'https://admin-test.rxhometest.com';
let adminUrl = 'https://admin.rxhometest.com/orders/clinic';
// let adminUrl = 'http://localhost:8080/orders/clinic';
let baseUrlTv = 'https://api-stage.independa.com';
// // 0bfd64fc-851c-4c4a-b963-4026d89d5d86
// e7c62133-8e96-44ed-96ac-b4981d903709
let fileBaseUrl = 'https://rxhometest.s3.amazonaws.com/web';
let mixpanelOriginUrl = 'https://qa-tv.independa.com';

if (IS_NEW_API) {
  mixpanelOriginUrl = 'https://tv-qa.independa.com';
}

if (IS_PROD) {
  baseUrl = 'https://api.rxhometest.com';
  url = 'https://rxhometest.com';
  qrUrl = 'https://qr.rxhometest.com';
  baseUrlTv = 'https://api-v2.independa.com';
  // // cd4557fc-5e90-459f-b47f-9575e644bde1
  mixpanelOriginUrl = 'https://tv.independa.com';
}

if (IS_LOCAL) {
  baseUrl = 'http://localhost:4000';
  baseUrlTv = 'http://localhost:4000';
  mixpanelOriginUrl = 'http://localhost:8080';
}

module.exports = {
  mixpanelOriginUrl,
  baseUrl,
  url,
  qrUrl,
  baseUrlTv,
  fileBaseUrl,
  adminUrl,
};
